// 创建一个事件总线
class EventBus {
    constructor() {
        this.events = {};
    }
    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }
    off(event, callback) {
        if (this.events[event]) {
            const index = this.events[event].indexOf(callback);
            if (index !== -1) {
                this.events[event].splice(index, 1);
            }
        }
    }
    once(event, callback) {
        this.removeEvent(event);
        this.on(event, callback);
    }
    clearAllEvents() {
        this.events = {};
    }
    removeEvent(event) {
        this.events[event] = [];
    }
    emit(event, data) {
        if (this.events[event]) {
            this.events[event].forEach(callback => callback(data));
        }
    }
}

export const eventBus = new EventBus();
export const UPDATE_TABLE_DATA = 'updateTableData';
